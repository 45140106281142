import { fetchPost } from '@/router/https'
import axios from 'axios'

// 店铺商品列表详情
const takeOutDetail = (data) => {
  return fetchPost('/takeout/user/category/storelist', data)
}

// 商品分类
const cateShop = (data) => {
  return fetchPost('/takeout/user/category/storegrouplist', data)
}

// 评价
const shopevaluate = (data) => {
  return fetchPost('/takeout/user/category/shopevaluate', data)
}

// 商品详情
const shopDetail = (data) => {
  return fetchPost('/takeout/user/category/shopgooddetails', data)
}

// 店铺/店铺商品收藏
const shopCollect = (data) => {
  return fetchPost('/takeout/user/favorite/save', data)
}

// 取消店铺/店铺商品收藏
const cancelShopCollect = (data) => {
  return fetchPost('/takeout/user/favorite/delete', data)
}

// 我的订单data
const getOrder = (data) => {
  return fetchPost('/takeout/user/order/myorder', data)
}

// 评价订单商品
const goodsevaluate = (data) => {
  return fetchPost('/takeout/user/takeoutordergoods/goodsevaluate', data)
}

// 搜索商品
const searchShops = (data) => {
  return fetchPost('/takeout/user/category/selectgoodsinshop', data)
}

// 规格详情
const selectStandards = (data) => {
  return fetchPost('/takeout/user/takeoutordergoods/selectStandards', data)
}

// 订单结算
const findinfo = (data) => {
  return fetchPost('/takeout/user/takeoutordergoods/findinfo', data)
}

// 提交订单
const saveorders = (data) => {
  return fetchPost('/takeout/user/takeoutordergoods/saveorders', data)
}

// 领取优惠券
const couponuser = (data) => {
  return fetchPost('/takeout/user/couponuser/save', data)
}

// 支付
const paynow = (data) => {
  console.log(data)
  return fetchPost('/takeout/user/paynow', data)
}

// 热门搜索
const hotSearch = (data) => {
  return fetchPost('/takeout/user/category/searchhothistory', data)
}

// 历史
const historySearch = (data) => {
  return fetchPost('/takeout/user/category/searchhistory', data)
}

// 删除历史
const deletehistory = (data) => {
  return fetchPost('/takeout/user/category/deletehistory', data)
}

// 获取地址
const findaddres = (data) => {
  return fetchPost('/takeout/user/takeoutordergoods/findaddres', data)
}

// 是否为新用户
const newuserflag = (data) => {
  return fetchPost('/takeout/user/category/newuserflag', data)
}

// 获取距离事件
const distanceTime = (origin, destination) => { 
  return axios({
    url: '/v4/direction/bicycling',
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    params: {
      origin,
      destination,
      key: '6410e5b3032f5b63aa7199ff05ff151c'
    }
  })
}

// 订单详情 
const findorders = (data) => {
  return fetchPost('/takeout/user/takeoutordergoods/findorders', data)
}


export {
  takeOutDetail,
  cateShop,
  shopevaluate,
  shopDetail,
  shopCollect,
  cancelShopCollect,
  getOrder,
  goodsevaluate,
  searchShops,
  selectStandards,
  findinfo,
  saveorders,
  couponuser,
  paynow,
  hotSearch,
  historySearch,
  deletehistory,
  findaddres,
  distanceTime,
  newuserflag,
  findorders
}