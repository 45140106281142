<template>
  <div>
    <div style="display: flex; align-items: center;" class="Ge">
      <van-icon size="20" name="arrow-left" @click="$router.back()" />
      <van-search
        v-model="keyWord"
        shape="round"
        placeholder="请输入商品标题"
        @search="onSearch"
        ref="searchFocus"
      >
      </van-search>
      <van-button 
        color="linear-gradient(to right, #ff6034, #ee0a24)" 
        size="mini" 
        round
        @click="onSearch"
        type="primary"
        :autofocus="true"
      >搜索</van-button>
    </div>

    <div class="Ge-xian-bo">
      <div class="Ge-xian-bo-top" v-if="hotRecords.length">
        <p>热门搜索</p>
        <div class="Ge-xian-bo-top-record">
          <div 
            v-for="(item, index) in hotRecords" 
            :key="index"
            @click="hotHostory(item.content)"
          >{{ item.content }}</div>
        </div>
      </div>

      <div class="Ge-xian-bo-bottom" v-if="history.length">
        <div class="Ge-xian-bo-bottom-history">
          <span>搜索历史</span>
          <van-icon name="delete-o" @click="deleteHistory" />
        </div>

        <div class="Ge-xian-bo-bottom-records">
          <div 
            v-for="(item, index) in history" 
            :key="index"
            @click="clickKeyWord(item.content)"
          >{{ item.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import { hotSearch, historySearch, deletehistory } from '@/api/Ge'
import qs from 'qs' 
  export default {
    data () {
      return {
        keyWord: '',
        hotRecords: [],
        history: [],
      }
    },
    mounted () {
      this.onfocus()
    },
    created () {
      this.hotSearch()
      this.historySearch()
    },
    methods: {
      onSearch () {
        if (!this.keyWord) {
          this.$toast('请输入商品标题')
          return
        }
        this.$router.replace({
          path: 'searchResult',
          query: {
            shopId: this.$route.query.shopId,
            name: this.keyWord
          }
        })
      },

      deleteHistory () {
        Dialog.confirm({
        className: 'GeclassName',
        title: '清空历史记录',
        confirmButtonText: '确定',
      })
        .then(() => {
          deletehistory(qs.stringify({
            shopId: this.$route.query.shopId
          })).then(res => {
            if (res.data.code === 0) {
              this.$router.go(0)
            }
          })
        })
        .catch(() => {
        })
      },

      clickKeyWord (keyWord) {
        this.keyWord = keyWord
        this.onSearch()
      },

      hotHostory (keyWord) {
        this.keyWord = keyWord
        this.onSearch()
      },

      onfocus () {
        document.getElementsByClassName( 'van-field__control' )[0].focus();
      },

      hotSearch () {
        hotSearch(qs.stringify({
          shopId: this.$route.query.shopId
        })).then(res => {
          if (res.data.code === 0) {
            this.hotRecords = res.data.data
          }
        })
      },

      historySearch () {
        historySearch(qs.stringify({
          shopId: this.$route.query.shopId
        })).then(res => {
          console.log(res, '历史搜索')
          if (res.data.code === 0) {
            this.history = res.data.data.records
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.Ge {
  width: 96%;
  margin: 0 2%;
  .van-search {
    flex: 1;
  }
  .van-button--mini {
    padding: 0 10px;
  }
}
.Ge-xian-bo {
  width: 94%;
  margin: 15px 3% 0;
  font-size: 12px;
  color: #828283;
  &-top-record,
  .Ge-xian-bo-bottom-records {
    display: flex;
    flex-wrap: wrap;
    div {
      background: #f7f8fa;
      padding: 2px 10px;
      margin: 0 5px;
      border-radius: 12px;
      margin-top: 5px;
    }
  }
}
.Ge-xian-bo-bottom {
  margin-top: 15px;
  &-history {
    display: flex;
   justify-content: space-between;
   align-items: center;
  }
}
/deep/ .GeclassName {
  .van-dialog--round-button {
    color: black !important;
  }
}
</style>